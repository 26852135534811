import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import {
  assignDeviceApi,
  unassignDeviceApi,
  fetchAllDevicesApi,
  fetchDevicesApi,
  fetchDeviceRemoteApi,
  assignDeviceForAnyCustomerApi,
  unassignAnyDeviceApi,
  resetDeviceApi,
  resetAnyDeviceApi,
  editAnyDeviceApi,
  editDeviceApi,
  IGetDevicesArgs,
} from './devices.api';
import { actions as appActions } from '../app/app.slice';
import { IbaseDoc, ApiError, IResponseParams } from '../../components/types';

export interface IDevice extends IbaseDoc {
  userId: string;
  deviceId: string;
  status: string;
  deviceName: string;
  userDeviceName: string;
  rebootStatus: string;
}

export interface IGetDeviceResponse extends IResponseParams {
  data: IDevice[];
}

export interface IDevicesState {
  list: IGetDeviceResponse;
  listAll: IGetDeviceResponse;
  listRemote: IDevice[];
  assignDevice: any;
  unassignDevice: any;
  resetDevice: any;
  loading: boolean;
  success: boolean;
  error: any;
  all: boolean;
}

const initialState: IDevicesState = {
  list: { data: [], total: 0 },
  listAll: { data: [], total: 0 },
  listRemote: [],
  assignDevice: null,
  unassignDevice: null,
  resetDevice: null,
  loading: false,
  success: false,
  error: null,
  all: false,
};

export const fetchDevicesAsync = createAsyncThunk(
  'devices/fetch',
  async (
    {
      id = '',
      limit = 10,
      offset = 0,
      sortOrder = { name: 'createdAt', direction: 'desc' },
      search = '',
      filters = [],
    }: IGetDevicesArgs,
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await fetchDevicesApi({
        id,
        limit,
        offset,
        sortOrder,
        search,
        filters,
      });
      return response.data;
    } catch (error: ApiError) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const fetchAllDevicesAsync = createAsyncThunk(
  'devices/fetchAll',
  async (
    {
      id = '',
      limit = 10,
      offset = 0,
      sortOrder = { name: 'createdAt', direction: 'desc' },
      search = '',
      filters = [],
    }: IGetDevicesArgs,
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await fetchAllDevicesApi({
        id,
        limit,
        offset,
        sortOrder,
        search,
        filters,
      });
      return response.data;
    } catch (error: ApiError) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const fetchDevicesRemoteAsync = createAsyncThunk(
  'devices/fetchRemote',
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await fetchDeviceRemoteApi();
      return response.data;
    } catch (error: ApiError) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const assignDeviceAsync = createAsyncThunk<
  void,
  any,
  { state: RootState }
>(
  'devices/assignDevice',
  async (data, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await assignDeviceApi(data);
      const { devices } = getState();
      if (devices.all) {
        dispatch(fetchAllDevicesAsync({ limit: 500 }));
      } else {
        dispatch(fetchDevicesAsync({ limit: 500 }));
      }
      dispatch(
        appActions.triggerAlert({
          type: 'success',
          childern: 'successfully_created_device',
        })
      );
      return response.data;
    } catch (error: ApiError) {
      dispatch(
        appActions.triggerAlert({
          type: 'error',
          childern: error?.response?.data?.error || 'error_occurred',
        })
      );
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const assignDeviceForAnyCustomerAsync = createAsyncThunk(
  'devices/assignForAnyCustomer',
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await assignDeviceForAnyCustomerApi(data);
      dispatch(fetchDevicesAsync(data.customerId));
      dispatch(fetchDevicesRemoteAsync({}));
      dispatch(
        appActions.triggerAlert({
          type: 'success',
          childern: 'successfully_created_device',
        })
      );
      return response.data;
    } catch (error: ApiError) {
      dispatch(
        appActions.triggerAlert({
          type: 'error',
          childern: error?.response?.data?.error || 'error_occurred',
        })
      );
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const unassignDevicesAsync = createAsyncThunk<
  void,
  any,
  { state: RootState }
>(
  'devices/unassignDevice',
  async (data, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await unassignDeviceApi(data);
      const { devices } = getState();
      if (devices.all) {
        dispatch(fetchAllDevicesAsync({ limit: 500 }));
      } else {
        dispatch(fetchDevicesAsync({ limit: 500 }));
      }
      dispatch(
        appActions.triggerAlert({
          type: 'success',
          childern: 'successfully_unassigned_device',
        })
      );
      return response.data;
    } catch (error: ApiError) {
      dispatch(
        appActions.triggerAlert({
          type: 'error',
          childern: error?.response?.data?.error || 'error_occurred',
        })
      );
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const unassignAnyDevicesAsync = createAsyncThunk<
  void,
  any,
  { state: RootState }
>(
  'devices/unassignAnyDevice',
  async (data, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await unassignAnyDeviceApi({ deviceId: data.deviceId });
      const { devices } = getState();
      if (devices.all) {
        dispatch(fetchAllDevicesAsync({ limit: 500 }));
      } else {
        dispatch(fetchDevicesAsync({ id: data.customerId, limit: 500 }));
        dispatch(fetchDevicesRemoteAsync({ limit: 500 }));
      }
      dispatch(
        appActions.triggerAlert({
          type: 'success',
          childern: 'successfully_unassigned_device',
        })
      );
      return response.data;
    } catch (error: ApiError) {
      dispatch(
        appActions.triggerAlert({
          type: 'error',
          childern: error?.response?.data?.error || 'error_occurred',
        })
      );
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const resetDevicesAsync = createAsyncThunk<
  void,
  any,
  { state: RootState }
>('devices/reset', async (id, { getState, rejectWithValue, dispatch }) => {
  try {
    const response = await resetDeviceApi(id);
    const { devices } = getState();
    if (devices.all) {
      dispatch(fetchAllDevicesAsync({ limit: 500 }));
    } else {
      dispatch(fetchDevicesAsync({ limit: 500 }));
    }
    return response.data;
  } catch (error: ApiError) {
    dispatch(
      appActions.triggerAlert({
        type: 'error',
        childern: error?.response?.data?.error || 'error_occurred',
      })
    );
    return rejectWithValue(error?.response?.data);
  }
});

export const resetAnyDevicesAsync = createAsyncThunk<
  void,
  any,
  { state: RootState }
>('devices/resetAny', async (id, { getState, rejectWithValue, dispatch }) => {
  try {
    const response = await resetAnyDeviceApi(id);
    const { devices } = getState();
    if (devices.all) {
      dispatch(fetchAllDevicesAsync({ limit: 500 }));
    } else {
      dispatch(fetchDevicesAsync({ limit: 500 }));
    }
    return response.data;
  } catch (error: ApiError) {
    dispatch(
      appActions.triggerAlert({
        type: 'error',
        childern: error?.response?.data?.error || 'error_occurred',
      })
    );
    return rejectWithValue(error?.response?.data);
  }
});

export const editDevicesAsync = createAsyncThunk<
  void,
  any,
  { state: RootState }
>(
  'devices/editDevice',
  async (data, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await editDeviceApi(data);
      const { devices } = getState();
      if (devices.all) {
        dispatch(fetchAllDevicesAsync({ limit: 500 }));
      } else {
        dispatch(fetchDevicesAsync({ limit: 500 }));
      }
      dispatch(
        appActions.triggerAlert({
          type: 'success',
          childern: 'successfully_updated_device',
        })
      );
      return response.data;
    } catch (error: ApiError) {
      dispatch(
        appActions.triggerAlert({
          type: 'error',
          childern: error?.response?.data?.error || 'error_occurred',
        })
      );
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const editAnyDevicesAsync = createAsyncThunk<
  void,
  any,
  { state: RootState }
>(
  'devices/editAnyDevice',
  async (data, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await editAnyDeviceApi(data);
      const { devices } = getState();
      if (devices.all) {
        dispatch(fetchAllDevicesAsync({ limit: 500 }));
      } else {
        dispatch(fetchDevicesAsync({ limit: 500 }));
      }
      dispatch(
        appActions.triggerAlert({
          type: 'success',
          childern: 'successfully_updated_device',
        })
      );
      return response.data;
    } catch (error: ApiError) {
      dispatch(
        appActions.triggerAlert({
          type: 'error',
          childern: error?.response?.data?.error || 'error_occurred',
        })
      );
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const devicesSlice = createSlice({
  name: 'devices',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // fetch devices
    builder.addCase(fetchDevicesAsync.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchDevicesAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.list = action?.payload;
      state.all = false;
    });
    builder.addCase(fetchDevicesAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // fetch all devices
    builder.addCase(fetchAllDevicesAsync.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchAllDevicesAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.listAll = action?.payload;
      state.all = true;
    });
    builder.addCase(fetchAllDevicesAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // fetch remote devices
    builder.addCase(fetchDevicesRemoteAsync.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchDevicesRemoteAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.listRemote = action?.payload?.data;
    });
    builder.addCase(fetchDevicesRemoteAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // Assign device
    builder.addCase(assignDeviceAsync.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(assignDeviceAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.assignDevice = action.payload;
    });
    builder.addCase(assignDeviceAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // Assign device for any customer
    builder.addCase(
      assignDeviceForAnyCustomerAsync.pending,
      (state, action) => {
        state.loading = true;
      }
    );
    builder.addCase(
      assignDeviceForAnyCustomerAsync.fulfilled,
      (state, action) => {
        state.loading = false;
        state.assignDevice = action.payload;
      }
    );
    builder.addCase(
      assignDeviceForAnyCustomerAsync.rejected,
      (state, action) => {
        state.loading = false;
        state.error = action.payload;
      }
    );

    // Unassign device
    builder.addCase(unassignDevicesAsync.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(unassignDevicesAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.unassignDevice = action.payload;
    });
    builder.addCase(unassignDevicesAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // Unassign any device
    builder.addCase(unassignAnyDevicesAsync.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(unassignAnyDevicesAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.unassignDevice = action.payload;
    });
    builder.addCase(unassignAnyDevicesAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // reset device
    builder.addCase(resetDevicesAsync.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(resetDevicesAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.resetDevice = action.payload;
    });
    builder.addCase(resetDevicesAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // reset any device
    builder.addCase(resetAnyDevicesAsync.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(resetAnyDevicesAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.resetDevice = action.payload;
    });
    builder.addCase(resetAnyDevicesAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // edit device
    builder.addCase(editDevicesAsync.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(editDevicesAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.resetDevice = action.payload;
    });
    builder.addCase(editDevicesAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // edit any device
    builder.addCase(editAnyDevicesAsync.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(editAnyDevicesAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.resetDevice = action.payload;
    });
    builder.addCase(editAnyDevicesAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export const { actions } = devicesSlice;

export const selectDevices = (state: RootState) => state.devices;

export default devicesSlice.reducer;
