/* eslint-disable no-nested-ternary */
import React, { memo, useCallback } from 'react';
import { map } from 'lodash';
import { withStyles, WithStyles, useTheme } from '@material-ui/core/styles';
import { CircularProgress, Grid } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { FormattedMessage, useIntl } from 'react-intl';
import { Call as CallIcon } from '@material-ui/icons';
import clsx from 'clsx';
import styles from './speedDials.styles';
import { Card } from '../../components/Card';
import { IconButton } from '../../components/Button';
import { Table } from '../../components/Table';
import { getDeviceStatus } from '../../helper/translationFunctions';
import { IRequestParams } from '../../components/types';
import { IDevicesState, IGetDeviceResponse } from '../devices/devices.slice';

interface ISpeedDialsView extends WithStyles<typeof styles> {
  devices: IDevicesState;
  devicesList: IGetDeviceResponse;
  handleSpeeddial: (data: any) => void;
  handleSpeeddialJoinCall: (id: string) => void;
  handleGetDevices: ({ limit, offset, sortOrder }: IRequestParams) => void;
}

const SpeedDialsView: React.FC<ISpeedDialsView> = ({
  classes,
  devices,
  devicesList,
  handleSpeeddial,
  handleSpeeddialJoinCall,
  handleGetDevices,
}: ISpeedDialsView) => {
  const intl = useIntl();
  const theme = useTheme();

  const isTabScreen = useMediaQuery(theme.breakpoints.down('md'));

  const speedDialColumns = [
    {
      name: 'deviceName',
      label: intl.formatMessage({
        id: 'name',
      }),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'rebootStatus',
      label: intl.formatMessage({
        id: 'status',
      }),
      options: {
        filter: true,
        sort: true,
        display: !isTabScreen,
      },
    },
    {
      name: 'action',
      label: intl.formatMessage({
        id: 'action',
      }),
      options: {
        filter: false,
        sort: false,
      },
    },
  ];

  const _handleSpeeddialButton = (row: any) => {
    const status = row?.rebootStatus;
    if (status === 'ready' || status === 'device_ready') {
      const data = {
        beginTime: new Date(),
        name: null,
        location: null,
        deviceId: row?._id,
      };
      handleSpeeddial(data);
    } else {
      const str1 = row?.commands?.meetingUrl;
      if (str1) {
        const str2 = str1.substring(0, str1.indexOf('?'));
        const meetingLocation = str2.substring(str2.lastIndexOf('/') + 1);

        handleSpeeddialJoinCall(meetingLocation);
      }
    }
  };

  const speedDialRowEvent = useCallback(() => {
    return map(devicesList.data, (row) => {
      const status = row?.rebootStatus;

      const getButtonData = () => {
        switch (status) {
          case 'device_call_ongoing':
          case 'ongoing': // Need to replace with the correct device status
            return {
              customStyles: clsx(
                classes.speedDialButtonCommon,
                classes.speedDialButtonBlue
              ),
              label: intl.formatMessage({
                id: 'join_call',
              }),
            };
          case 'device_ready':
          case 'ready':
            return {
              customStyles: clsx(
                classes.speedDialButtonCommon,
                classes.speedDialButtonGreen
              ),
              label: intl.formatMessage({
                id: 'start_call',
              }),
            };
          default:
            return {
              customStyles: clsx(
                classes.speedDialButtonCommon,
                classes.speedDialButtonGray
              ),
              disabled: true,
              label: '',
            };
        }
      };
      return {
        ...row,
        rebootStatus: getDeviceStatus(intl, row.rebootStatus),
        action: (
          <IconButton
            // color="primary"
            icon={<CallIcon />}
            id="speed-dial-table-action"
            variant="contained"
            onClick={() => _handleSpeeddialButton(row)}
            {...getButtonData()}
          />
        ),
      };
    });
  }, [devices]);

  const speedDialCard = (
    <Card customStyles={classes.card}>
      <div className={classes.speedDialWrapper}>
        <Table
          id="speed-dial-table"
          title={
            <div id="speed_dials">
              <FormattedMessage id="speed_dials" />
              {devices.loading && (
                <CircularProgress
                  size={24}
                  style={{ marginLeft: 15, position: 'relative', top: 4 }}
                />
              )}
            </div>
          }
          customStyles={classes.speedDialTable}
          columns={speedDialColumns}
          data={speedDialRowEvent()}
          pagination
          filter
        />
      </div>
    </Card>
  );

  return (
    <div className={classes.root}>
      {/* <Grid
        container
        direction="row"
        spacing={3}
        justifyContent="space-between"
        className={classes.topWrapper}
      >
        <Grid item xs={12} sm={12}>
          {speedDialCard}
        </Grid>
      </Grid> */}
      {speedDialCard}
    </div>
  );
};

export default memo(withStyles(styles)(SpeedDialsView));
