/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/require-default-props */
import React, {
  ChangeEvent,
  ChangeEventHandler,
  memo,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { AutocompleteChangeReason } from '@material-ui/lab/Autocomplete';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import IconButton from '@material-ui/core/IconButton';
import { FormattedMessage, useIntl } from 'react-intl';
import { map } from 'lodash';
import { CircularProgress, Divider, Grid } from '@material-ui/core';
import styles from './manageCustomers.styles';
import { Typography } from '../../components/Typography';
import { Card } from '../../components/Card';
import { Table } from '../../components/Table';
import { WordConfirmationModal } from './components';
import { TextField } from '../../components/TextField';
import { Button } from '../../components/Button';

import { AddMemberModal } from '../members/components';
import { validateContactNumber, validateEmail } from '../../helper/validation';
import { SelectDeviceModal } from '../devices/components';
import { AddGroupModal } from '../group/components';
import DeleteConfirmationModal from './components/deleteConfirmationModal';
import EditGroupModal from '../group/components/editGroupModal';
import { IGetMemberResponse, IMembersState } from '../members/members.slice';
import { IGroupsState, emptyGroup } from '../group/groups.slice';
import { IUser } from '../auth/auth.slice';
import { IDevicesState } from '../devices/devices.slice';
import { IRequestParams } from '../../components/types';
import { AutocompleteTextField } from '../../components/AutocompleteTextField';
// import WordConfirmationModal from './components/wordConfirmationModal';

interface IManageCustomerView extends WithStyles<typeof styles> {
  selectedCustomer: IUser;
  allCustomer: boolean;
  selectMember: IMembersState;
  membersList: IGetMemberResponse;
  subadminList: IGetMemberResponse;
  selectDevice: IDevicesState;
  selectGroup: IGroupsState;
  handleAddMember: (data: string) => void;
  handleEditCustomer: (data: any) => void;
  handleSelectDevice: (data: any) => void;
  handleAddGroup: (data: any) => void;
  backToCustomers: () => void;
  handleDeleteMember: (data: string) => void;
  handleDeleteDevice: (data: any) => void;
  handleDeleteGroup: (data: string) => void;
  handleEditGroup: (data: any) => void;
  handleDeleteCustomer: () => void;
  handleSuspendCustomer: () => void;
  handleUnsuspendCustomer: () => void;
  handleDeleteGroupMember: (id: string) => void;
  handleGetDevices: ({ limit, offset, sortOrder }: IRequestParams) => void;
  handleGetGroups: ({ limit, offset, sortOrder }: IRequestParams) => void;
  handleGetMembers: ({ limit, offset, sortOrder }: IRequestParams) => void;
}

interface IMemberInfoCard extends WithStyles<typeof styles> {
  name: IParentIdState;
  allCustomer: boolean;
  nameOnChange: any;
  email: IParentIdState;
  emailOnChange: any;
  contactNumber: IParentIdState;
  contactNumberOnChange: any;
  selectedCustomer: IUser;
  onClickActionButton: any;
  subadminList: IGetMemberResponse;
  autocompleteValue: any;
  handleAutocompleteChange: any;
  parentId: IParentIdState;
}

interface IParentIdState {
  value: string;
  error: boolean;
}

const MembersInfoRow = ({
  name2,
  disabled = false,
  value = '',
  error,
  placeholder,
  onChange,
  required,
  helperText,
}: {
  name2: string;
  disabled: boolean;
  value: string;
  error?: boolean;
  placeholder?: string;
  onChange?: ChangeEventHandler<HTMLTextAreaElement>;
  required?: boolean;
  helperText?: string | undefined;
}) => {
  return (
    <Grid container>
      <Grid item xs={2} style={{ display: 'flex', alignItems: 'center' }}>
        {name2}
      </Grid>
      <Grid item xs={10}>
        <TextField
          value={value}
          disabled={disabled}
          error={error}
          placeholder={placeholder}
          onChange={onChange}
          required
        />
      </Grid>
    </Grid>
  );
};

const MemberInfoCard = memo(
  withStyles(styles)(
    ({
      classes,
      name,
      allCustomer,
      nameOnChange,
      email,
      emailOnChange,
      contactNumber,
      contactNumberOnChange,
      selectedCustomer,
      onClickActionButton,
      subadminList,
      autocompleteValue,
      handleAutocompleteChange,
      parentId,
    }: IMemberInfoCard) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const intl = useIntl();
      return (
        <Card customStyles={classes.cardWithoutTable}>
          <div className={classes.cardContentWrapper}>
            <Typography variant="h6" className={classes.cardTitle}>
              <FormattedMessage id="details" />
            </Typography>
            <div className={classes.memberTextfieldsWrapper}>
              <MembersInfoRow
                name2={intl.formatMessage({
                  id: 'name',
                })}
                disabled={false}
                value={name.value}
                error={name.error}
                placeholder={intl.formatMessage({
                  id: 'name',
                })}
                onChange={nameOnChange}
                required
              />
              <MembersInfoRow
                name2={intl.formatMessage({
                  id: 'email',
                })}
                disabled={false}
                value={email.value}
                error={email.error}
                placeholder={intl.formatMessage({
                  id: 'email',
                })}
                onChange={emailOnChange}
                required
              />
              <MembersInfoRow
                name2={intl.formatMessage({
                  id: 'contact_number',
                })}
                disabled={false}
                value={contactNumber.value}
                error={contactNumber.error}
                placeholder={intl.formatMessage({
                  id: 'contact_number',
                })}
                onChange={contactNumberOnChange}
                required
              />
              {allCustomer ? (
                <Grid container>
                  <Grid
                    item
                    xs={2}
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    {intl.formatMessage({
                      id: 'managed_by',
                    })}
                  </Grid>
                  <Grid item xs={10}>
                    <AutocompleteTextField
                      multiple={false}
                      limitTags={1}
                      id="select-subadmin-dropdown"
                      options={subadminList.data}
                      getOptionLabel={(option) => option?.profile?.name}
                      value={autocompleteValue}
                      onChange={handleAutocompleteChange}
                      label={intl.formatMessage({
                        id: 'managed_by',
                      })}
                      placeholder={intl.formatMessage({
                        id: 'managed_by',
                      })}
                      error={parentId.error}
                    />
                  </Grid>
                </Grid>
              ) : (
                ''
              )}

              <MembersInfoRow
                name2={intl.formatMessage({
                  id: 'role',
                })}
                disabled
                value={selectedCustomer?.role as string}
              />
              <MembersInfoRow
                name2={intl.formatMessage({
                  id: 'status',
                })}
                disabled
                value={selectedCustomer?.status as string}
              />

              {/* <MembersInfoRow
              name={intl.formatMessage({
                id: 'parent',
              })}
            /> */}
            </div>
          </div>
          <div style={{ float: 'right' }}>
            <Button
              id="group-management"
              label={intl.formatMessage({
                id: 'update',
              })}
              variant="contained"
              onClick={onClickActionButton}
            />
          </div>
        </Card>
      );
    }
  )
);

const ManageCustomerView: React.FC<IManageCustomerView> = ({
  classes,
  selectedCustomer,
  allCustomer,
  selectMember,
  membersList,
  subadminList,
  selectDevice,
  selectGroup,
  handleAddMember,
  handleEditCustomer,
  handleSelectDevice,
  handleAddGroup,
  backToCustomers,
  handleDeleteMember,
  handleDeleteDevice,
  handleDeleteGroup,
  handleEditGroup,
  handleDeleteCustomer,
  handleSuspendCustomer,
  handleUnsuspendCustomer,
  handleDeleteGroupMember,
  handleGetDevices,
  handleGetGroups,
  handleGetMembers,
}: IManageCustomerView) => {
  const [openAddMemberModal, setOpenAddMemberModal] = useState(false);
  const [openAddDeviceModal, setOpenAddDeviceModal] = useState(false);
  const [openAddGroupModal, setOpenAddGroupModal] = useState(false);
  const [openEditGroupModal, setOpenEditGroupModal] = useState({
    open: false,
    selectedGroup: emptyGroup,
  });
  const [
    openDeleteWordConfirmationModal,
    setOpenDeleteWordConfirmationModal,
  ] = useState(false);
  const [removeConfirmationModal, setRemoveConfirmationModal] = useState({
    open: false,
    name: '',
    onclick: null,
  });
  const [
    openRevokeConfirmationModal,
    setOpenRevokeConfirmationModal,
  ] = useState(false);

  const [isSuspend, setIsSuspend] = useState({
    bool: false,
    string: 'unsuspend',
  });

  const intl = useIntl();

  const membersTableHeadings = [
    {
      name: 'name',
      label: intl.formatMessage({
        id: 'name',
      }),
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'email',
      label: intl.formatMessage({
        id: 'email',
      }),
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'status',
      label: intl.formatMessage({
        id: 'status',
      }),
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'action',
      label: intl.formatMessage({
        id: 'action',
      }),
      options: {
        filter: false,
        sort: false,
      },
    },
  ];

  const devicesTableHeadings = [
    {
      name: 'id',
      label: intl.formatMessage({
        id: 'id',
      }),
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'name',
      label: intl.formatMessage({
        id: 'name',
      }),
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'rebootStatus',
      label: intl.formatMessage({
        id: 'reboot_status',
      }),
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'action',
      label: intl.formatMessage({
        id: 'action',
      }),
      options: {
        filter: false,
        sort: false,
      },
    },
  ];

  const renderMemberTableRow = useCallback(() => {
    const getStatus = (row: IUser) => {
      if (!row.isVerified) {
        return intl.formatMessage({
          id: 'pending',
        });
      }
      if (row.isVerified && row.status === 'suspend') {
        return intl.formatMessage({
          id: 'suspend_simple',
        });
      }
      if (row.isVerified && row.status === 'active') {
        return intl.formatMessage({
          id: 'active',
        });
      }
      return 'none';
    };
    return map(membersList.data, (row) => {
      const memberName = row.profile.name;
      const memberId = row._id;
      return {
        ...row,
        name: row.profile.name,
        status: getStatus(row),
        action: (
          <IconButton
            aria-label={intl.formatMessage({
              id: 'remove',
            })}
            color="inherit"
            size="small"
            onClick={() =>
              onClickRemove(memberName, () => {
                handleDeleteMember(memberId);
                setRemoveConfirmationModal({
                  open: false,
                  name: '',
                  onclick: null,
                });
              })
            }
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        ),
      };
    });
  }, [intl, membersList]);

  const MemberDataCard = () => {
    return (
      <Card>
        <div className={classes.cardContentWrapper}>
          <div style={{ float: 'right' }}>
            <Button
              id="add-member"
              label={intl.formatMessage({
                id: 'add_member',
              })}
              variant="contained"
              onClick={() => setOpenAddMemberModal(true)}
            />
          </div>
          <Table
            customStyles={classes.tableWrapper}
            id="member-data-table"
            title={
              <>
                <Typography variant="h6" className={classes.cardTitle}>
                  <FormattedMessage id="members" />
                  {selectMember.loading && (
                    <CircularProgress
                      size={24}
                      style={{ marginLeft: 15, position: 'relative', top: 4 }}
                    />
                  )}
                </Typography>
              </>
            }
            columns={membersTableHeadings}
            data={renderMemberTableRow()}
            pagination
            serverSide
            filter={false}
            count={membersList.total}
            dataRefreshFunc={handleGetMembers}
          />
        </div>
      </Card>
    );
  };

  useEffect(() => {
    if (selectedCustomer) {
      setName({
        value: selectedCustomer?.profile?.name,
        error: !selectedCustomer?.profile?.name,
      });
      setContactNumber({
        value: selectedCustomer?.profile?.contactNumber || '',
        error: !selectedCustomer?.profile?.contactNumber,
      });
      setEmail({
        value: selectedCustomer?.email,
        error: !selectedCustomer?.email,
      });
      if (selectedCustomer?.role === 'customer') {
        setIsCustomer(true);
      } else {
        setIsCustomer(false);
      }
      if (selectedCustomer?.status === 'suspend') {
        setIsSuspend({
          bool: true,
          string: 'unsuspend',
        });
      } else {
        setIsSuspend({
          bool: false,
          string: 'revoke',
        });
      }

      let autocompleteValue = { _id: 'none', profile: { name: 'none' } };
      for (const subadmin of subadminList.data) {
        if (selectedCustomer?.parentId?.id === subadmin._id) {
          autocompleteValue = subadmin;
        }
      }
      setAutocompleteValue(autocompleteValue);
      setParentId({
        value: autocompleteValue._id,
        error: autocompleteValue.profile.name === 'none',
      });
    }
  }, [selectedCustomer, subadminList]);

  const [name, setName] = useState<IParentIdState>({
    error: false,
    value: '',
  });
  const [contactNumber, setContactNumber] = useState<IParentIdState>({
    error: false,
    value: '',
  });
  const [email, setEmail] = useState<IParentIdState>({
    error: false,
    value: '',
  });

  const [parentId, setParentId] = useState<IParentIdState>({
    error: false,
    value: '',
  });
  const [autocompleteValue, setAutocompleteValue] = useState<any>({
    _id: 'none',
    profile: { name: 'none' },
  });

  const [isCustomer, setIsCustomer] = useState(true);

  const _validate = () => {
    let isValidate = true;
    if (!name.value || name.error) {
      isValidate = false;
      setName({ ...name, error: true });
    }
    if (!contactNumber.value || contactNumber.error) {
      isValidate = false;
      setContactNumber({ ...contactNumber, error: true });
    }
    if (!email.value || email.error) {
      isValidate = false;
      setEmail({ ...email, error: true });
    }
    if (allCustomer && (parentId.value === '' || parentId.error)) {
      isValidate = false;
      setParentId({ ...parentId, error: true });
    }
    return isValidate;
  };

  const onClickActionButton = () => {
    if (_validate()) {
      const customerData = {
        id: selectedCustomer?._id,
        name: name.value,
        contactNumber: contactNumber.value,
        email: email.value,
        ...(allCustomer && { parentId: parentId.value }),
      };
      handleEditCustomer(customerData);
    }
  };

  const nameOnChange = (e: { target: { value: string } }) => {
    setName({
      value: e.target.value,
      error: !e.target.value,
    });
  };

  const contactNumberOnChange = (e: { target: { value: string } }) => {
    const error = validateContactNumber(e.target.value);
    setContactNumber({ value: e.target.value, error });
  };

  const emailOnChange = (e: { target: { value: string } }) => {
    const error = validateEmail(e.target.value);
    setEmail({ value: e.target.value, error });
  };

  const handleAutocompleteChange = (
    event: ChangeEvent<{}>,
    value: any,
    reason: AutocompleteChangeReason
  ) => {
    setParentId({
      value: value?._id,
      error: value?._id === undefined,
    });
    setAutocompleteValue(value);
  };

  // eslint-disable-next-line no-shadow
  const onClickRemove = (name: string, onclick: any) => {
    setRemoveConfirmationModal({
      open: true,
      name,
      onclick,
    });
  };

  const EachGroup = ({ group }: any) => {
    return (
      <Grid item xs={12} sm={4}>
        <div className={classes.groupTitleWrapper}>
          <Typography variant="body1" className={classes.groupTitle}>
            {group.name}
          </Typography>
          <div>
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() =>
                setOpenEditGroupModal({
                  open: true,
                  selectedGroup: group,
                })
              }
            >
              <EditIcon fontSize="inherit" />
            </IconButton>
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() =>
                onClickRemove(group.name, () => {
                  handleDeleteGroup(group._id);
                  setRemoveConfirmationModal({
                    open: false,
                    name: '',
                    onclick: null,
                  });
                })
              }
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </div>
        </div>
        <Divider />
        {group.members?.map((member: any, index: number) => {
          const memberName = member?.userId?.profile?.name;
          return (
            <div style={{ display: 'flex' }} key={index}>
              <Typography className={classes.groupMemberName} variant="body2">
                {memberName}
              </Typography>
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                onClick={() =>
                  onClickRemove(memberName, () => {
                    handleDeleteGroupMember(member.id);
                    setRemoveConfirmationModal({
                      open: false,
                      name: '',
                      onclick: null,
                    });
                  })
                }
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            </div>
          );
        })}
      </Grid>
    );
  };

  const GroupManageCard = () => {
    return (
      <Card customStyles={classes.cardWithoutTable}>
        <div style={{ float: 'right' }}>
          <Button
            id="add-group"
            label={intl.formatMessage({
              id: 'add_group',
            })}
            variant="contained"
            onClick={() => setOpenAddGroupModal(true)}
          />
        </div>
        <div className={classes.cardContentWrapper}>
          <Typography variant="h6" className={classes.cardTitle}>
            <FormattedMessage id="groups" />
          </Typography>
          <Grid container spacing={3} className={classes.groupWrapper}>
            {selectGroup.list.data &&
              selectGroup.list.data.map(
                (group: { _id: string; name: string; members: any[] }) => (
                  <EachGroup
                    group={group}
                    title={group.name}
                    members={group.members}
                    key={group._id}
                  />
                )
              )}
          </Grid>
        </div>
      </Card>
    );
  };

  const renderDeviceTableRow = useCallback(() => {
    return map(selectDevice.list.data, (row) => {
      return {
        ...row,
        name: row.deviceName,
        action: (
          <IconButton
            aria-label={intl.formatMessage({
              id: 'remove',
            })}
            color="inherit"
            size="small"
            onClick={() =>
              onClickRemove(row.deviceName, () => {
                handleDeleteDevice({ deviceId: row?.id });
                setRemoveConfirmationModal({
                  open: false,
                  name: '',
                  onclick: null,
                });
              })
            }
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        ),
      };
    });
  }, [selectDevice.list]);

  const DevicesCard = () => {
    return (
      <Card>
        <div className={classes.cardContentWrapper}>
          <div style={{ float: 'right' }}>
            <Button
              id="add-device"
              label={intl.formatMessage({
                id: 'add_device',
              })}
              variant="contained"
              onClick={() => setOpenAddDeviceModal(true)}
            />
          </div>
          <Table
            customStyles={classes.tableWrapper}
            id="device-data-table"
            title={
              <Typography variant="h6" className={classes.cardTitle}>
                <FormattedMessage id="devices" />
                {selectDevice.loading && (
                  <CircularProgress
                    size={24}
                    style={{ marginLeft: 15, position: 'relative', top: 4 }}
                  />
                )}
              </Typography>
            }
            columns={devicesTableHeadings}
            data={renderDeviceTableRow()}
            pagination
            serverSide
            filter={false}
            count={selectDevice.list.total}
            dataRefreshFunc={handleGetDevices}
          />
        </div>
      </Card>
    );
  };

  return (
    <div className={classes.root}>
      <div className={classes.topWrapper} id="customers-view">
        <IconButton color="inherit" size="medium" onClick={backToCustomers}>
          <ArrowBackIosIcon fontSize="inherit" />
        </IconButton>
        <Typography variant="h3" component="h2" color="textPrimary">
          {isCustomer ? (
            <FormattedMessage id="edit_customer" />
          ) : (
            <FormattedMessage id="edit_subadmin" />
          )}
        </Typography>
      </div>
      <div className={classes.bottomWrapper}>
        <Grid
          className={classes.cardGridWrapper}
          container
          spacing={3}
          justifyContent="space-between"
          style={{ height: '100%' }}
        >
          <Grid className={classes.mainGridItem} item xs={12} sm={6}>
            <MemberDataCard />
          </Grid>
          <Grid className={classes.mainGridItem} item xs={12} sm={6}>
            <MemberInfoCard
              name={name}
              allCustomer={allCustomer}
              nameOnChange={nameOnChange}
              email={email}
              emailOnChange={emailOnChange}
              contactNumber={contactNumber}
              contactNumberOnChange={contactNumberOnChange}
              selectedCustomer={selectedCustomer}
              onClickActionButton={onClickActionButton}
              subadminList={subadminList}
              autocompleteValue={autocompleteValue}
              handleAutocompleteChange={handleAutocompleteChange}
              parentId={parentId}
            />
          </Grid>
          <Grid className={classes.mainGridItem} item xs={12} sm={6}>
            <GroupManageCard />
          </Grid>
          <Grid className={classes.mainGridItem} item xs={12} sm={6}>
            <DevicesCard />
          </Grid>
        </Grid>
      </div>
      <div className={classes.deleteButtonsWrapper}>
        <Button
          id="delete-customer"
          label={intl.formatMessage({
            id: 'delete_customer_record',
          })}
          variant="contained"
          onClick={() => setOpenDeleteWordConfirmationModal(true)}
        />
        <Button
          id="revoke-member"
          label={
            isSuspend.bool
              ? intl.formatMessage({
                  id: 'gain_system_access',
                })
              : intl.formatMessage({
                  id: 'revoke_system_access',
                })
          }
          variant="contained"
          onClick={() => setOpenRevokeConfirmationModal(true)}
        />
      </div>

      <AddGroupModal
        selectGroup={selectGroup}
        selectMember={selectMember}
        selectDevice={selectDevice}
        open={openAddGroupModal}
        handleClose={() => setOpenAddGroupModal(false)}
        handleAddGroup={handleAddGroup}
      />
      <EditGroupModal
        selectGroup={selectGroup}
        selectMember={selectMember}
        selectDevice={selectDevice}
        open={openEditGroupModal.open}
        handleClose={() =>
          setOpenEditGroupModal({
            open: false,
            selectedGroup: emptyGroup,
          })
        }
        handleEditGroup={handleEditGroup}
        selectedGroup={openEditGroupModal.selectedGroup}
        allGroups={false}
      />
      <SelectDeviceModal
        open={openAddDeviceModal}
        handleClose={() => setOpenAddDeviceModal(false)}
        handleSelectDevice={handleSelectDevice}
        selectDevice={selectDevice}
      />
      <AddMemberModal
        selectMember={selectMember}
        open={openAddMemberModal}
        handleClose={() => setOpenAddMemberModal(false)}
        handleAddMember={handleAddMember}
      />
      <WordConfirmationModal
        open={openDeleteWordConfirmationModal}
        typeWord={intl.formatMessage({
          id: 'delete',
        })}
        modalTitle={intl.formatMessage({
          id: 'delete_user',
        })}
        modalDescription={intl.formatMessage(
          {
            id: 'are_you_sure_you_want_to_delete',
          },
          {
            name: selectedCustomer?.profile?.name,
            action: intl.formatMessage({
              id: 'delete',
            }),
          }
        )}
        actinButtonLabel="delete"
        onClickActionButton={() => {
          handleDeleteCustomer();
          setOpenDeleteWordConfirmationModal(false);
          backToCustomers();
        }}
        handleClose={() => setOpenDeleteWordConfirmationModal(false)}
      />
      <WordConfirmationModal
        open={openRevokeConfirmationModal}
        typeWord={intl.formatMessage({
          id: isSuspend.string,
        })}
        modalTitle={
          isSuspend.bool
            ? intl.formatMessage({
                id: 'gain_system_access',
              })
            : intl.formatMessage({
                id: 'revoke_system_access',
              })
        }
        modalDescription={intl.formatMessage(
          {
            id: 'are_your_sure_you_want_to_revoke_unsuspend',
          },
          {
            name: selectedCustomer?.profile?.name,
            action: intl.formatMessage({
              id: isSuspend.string,
            }),
          }
        )}
        onClickActionButton={() => {
          if (isSuspend.bool) {
            handleUnsuspendCustomer();
          } else {
            handleSuspendCustomer();
          }
          setOpenRevokeConfirmationModal(false);
        }}
        actinButtonLabel={isSuspend.string}
        handleClose={() => setOpenRevokeConfirmationModal(false)}
      />
      <DeleteConfirmationModal
        open={removeConfirmationModal.open}
        onClickActionButton={removeConfirmationModal.onclick}
        handleClose={() =>
          setRemoveConfirmationModal({
            open: false,
            name: '',
            onclick: null,
          })
        }
        modalDescription={intl.formatMessage(
          {
            id: 'are_you_sure_you_want_to_remove',
          },
          {
            name: removeConfirmationModal.name,
          }
        )}
      />
    </div>
  );
};

export default memo(withStyles(styles)(ManageCustomerView));
