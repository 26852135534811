import { createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';

const styles = (theme: Theme) => {
  return createStyles({
    root: {
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'auto',
    },
    card: {
      height: '100%',
      paddingTop: 0,
      paddingBottom: 5,
      [theme.breakpoints.down('xs')]: {
        paddingRight: 5,
      },
    },
    topWrapper: {
      paddingBottom: 24,
    },
    speedDialWrapper: {
      height: '100%',
    },
    speedDialTable: {
      // width: 'calc(100% - 24px)',
      overflow: 'auto',
      '& .MuiTableCell-root': {
        textTransform: 'capitalize',
      },
    },
    speedDialButtonCommon: {
      '& .MuiButtonBase-root': {
        width: '138px',
        height: '48px',
        borderRadius: '5px',
        '&:hover': {
          // backgroundColor: 'unset',
        },
      },
    },
    speedDialButtonBlue: {
      '& .MuiButtonBase-root': {
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
          // backgroundColor: 'unset',
        },
      },
    },
    speedDialButtonGreen: {
      '& .MuiButtonBase-root': {
        backgroundColor: '#28A745',
        '&:hover': {
          backgroundColor: '#268e3e',
        },
      },
    },
    speedDialButtonGray: {
      '& .MuiButtonBase-root': {
        backgroundColor: theme.palette.secondary.main,
        '&:hover': {
          // backgroundColor: 'unset',
        },
      },
      '& .MuiButton-startIcon': {
        color: '#fff',
        margin: 0,
      },
    },
    speedDialTitle: {
      marginBottom: '1.2rem',
    },
    cardTopSpacing: {
      marginTop: '2rem',
    },
  });
};

export default styles;
